import React from "react";
import Header from "../components/Header";
import { Container } from "react-bootstrap";
import Emoji from "../components/Emoji";

export default function ErrorPage() {
  return (
    <>
      <Header />
      <Container fluid>
        <br></br>
        <Container fluid className="col-md-6 mb-4">
          <h2>Oops!</h2>
          <p>
            <Emoji symbol="😳" /> Sorry, we can&apos;t find that page. If you
            have any questions, just{" "}
            <a href="mailto:hello@joinladle.com">email us</a> and we&apos;ll get
            back to you ASAP.
          </p>
        </Container>
      </Container>
    </>
  );
}

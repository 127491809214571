import React, { useEffect, useState } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { LinkContainer } from "react-router-bootstrap";
import Emoji from "../components/Emoji";
import Header from "../components/Header";
import { firestore } from "../firebase/firebase";

export default function IssuePage({ match }) {
  //   const { currentUser } = useContext(AuthContext);
  const {
    params: { issueId },
  } = match;
  const [issue, setIssue] = useState(null);
  const [error, setError] = useState(false);

  const fetchIssue = () => {
    const unsubscribe = firestore
      .collection("issues")
      .doc(issueId)
      .onSnapshot(
        (doc) => {
          const newIssue = {
            id: doc.id,
            ...doc.data(),
          };
          setIssue(newIssue);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  useEffect(fetchIssue, [issueId]);

  error && console.log(error);

  if (!issue) {
    return <></>;
  }

  return (
    <>
      <Header />
      <Container fluid className="col-md-6 mb-4">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item>
              <Emoji symbol="🏠" /> Home
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            <Emoji symbol="📨" /> Issue No.{issue.number}
          </Breadcrumb.Item>
        </Breadcrumb>
        <>
          <h3>Issue No.{issue.number}</h3>
          <br></br>
          <div
            style={{
              margin: 0,
              padding: "20px",
              backgroundColor: "#fff",
              border: "1px solid #212529",
              borderRadius: "4px",
            }}
          >
            {issue.emailHTML && ReactHtmlParser(issue.emailHTML)}
          </div>
        </>
      </Container>
    </>
  );
}

import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { firestore } from "../firebase/firebase";

export default function Feedback(props) {
  const [feedback, setFeedback] = React.useState("");

  const updateFeedback = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFeedback = {
      createdAt: new Date(),
      owner: {
        name: props.firestoreUser.name,
        uid: props.currentUser.uid,
        email: props.firestoreUser.email,
      },
      feedback: feedback,
    };

    await firestore.collection("feedback").add(newFeedback);

    setFeedback("");

    props.onHide();
  };

  return (
    <Modal {...props}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="add-feedback-modal">Send Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Let us know your thoughts, ideas, frustrations, and more!
          <br></br>
          <br></br>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={5}
              value={feedback}
              onChange={updateFeedback}
              label="Feedback"
              placeholder="Type your feedback here..."
              name="feedback"
              autoFocus
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="custom" type="submit">
            Send Feedback
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

import React, { useState, useContext } from "react";
import { firestore } from "../firebase/firebase";
import Header from "../components/Header";
import Emoji from "../components/Emoji";
import {
  Alert,
  Container,
  Form,
  Breadcrumb,
  Col,
  Row,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AuthContext } from "../firebase/authentication";

export default function ProfilePage() {
  const { currentUser, firestoreUser } = useContext(AuthContext);
  const [name, setName] = useState(firestoreUser.name);
  const [email, setEmail] = useState(firestoreUser.email);
  const [alertMessage, setAlertMessage] = useState("");
  const [show, setShow] = useState(null);

  const handleName = async (e) => {
    e.preventDefault();

    await firestore.collection("users").doc(currentUser.uid).update({
      name: name,
    });

    setAlertMessage("You updated your name ✅");
    setShow(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
    }, 3000);
  };

  const handleEmail = async (e) => {
    e.preventDefault();

    // await firestore.collection("users").doc(currentUser.uid).update({
    //   email: email,
    // });

    setAlertMessage("You updated your email ✅");
  };

  return (
    <>
      <Header />
      <Container fluid className="col-md-6 mb-4">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item>
              <Emoji symbol="🏠" /> Home
            </Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to="/account">
            <Breadcrumb.Item>
              <Emoji symbol="🛠️" /> Account
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            <Emoji symbol="😏" /> Profile
          </Breadcrumb.Item>
        </Breadcrumb>
        {show && (
          <Alert variant="success" onClose={() => setShow(false)} dismissible>
            <strong>{alertMessage}</strong>
          </Alert>
        )}
        <h2>Profile</h2>
        <p>Manage your profile, including name, email, and more.</p>

        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Name
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form onSubmit={handleName}>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name..."
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        ></Form.Control>
                        <Form.Text className="text-muted">
                          We recommend using your first name. We&apos;ll use
                          this name on Ladles and emails we send out. You can
                          always change it later.
                        </Form.Text>
                      </Form.Group>
                      <Button variant="custom" type="submit">
                        Save
                      </Button>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <br></br>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Email
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form onSubmit={handleEmail}>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email..."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          disabled
                        ></Form.Control>
                        <Form.Text className="text-muted">
                          We&apos;re working on allowing you to change your
                          email from here. In the meantime, just{" "}
                          <a href="mailto:bkkilaru@gmail.com">email us</a> and
                          we&apos;ll update your email for you.
                        </Form.Text>
                      </Form.Group>
                      <Button variant="custom" type="submit">
                        Save
                      </Button>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}

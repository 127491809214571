import React, { useState, useContext } from "react";
import { firestore } from "../firebase/firebase";
import { useHistory } from "react-router";
import Header from "../components/Header";
import { Alert, Form, Container, Button } from "react-bootstrap";
import { AuthContext } from "../firebase/authentication";
import { auth } from "../firebase/firebase";

export default function OnboardingPage() {
  const history = useHistory();
  const { currentUser, firestoreUser } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState(currentUser.displayName || "");

  if (firestoreUser?.submitted) {
    history.push("/");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    const newOnboarding = {
      createdAt: new Date(),
      name: name,
      email: currentUser.email,
      uid: currentUser.uid,
    };

    const addNewOnboarding = await firestore
      .collection("onboardings")
      .add(newOnboarding);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    console.log("Add New Onboarding", addNewOnboarding);
  };

  console.log("current user", currentUser);

  return (
    <>
      <Header />
      <Container fluid className="col-md-6 mb-4">
        <br></br>
        {submitted ? (
          <>
            <Alert variant="success">
              <Alert.Heading>
                <strong>You&apos;re all set! 🎬</strong>
              </Alert.Heading>
              We&apos;ll redirect you to your dashboard in just a moment, where
              you can begin working with Ladle.
            </Alert>
            <br></br>
          </>
        ) : (
          <>
            <h3>Let&apos;s get started! 🥳</h3>
            <br></br>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>
                  <strong>What should we call you?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">
                  We recommend using your first name. We&apos;ll use this name
                  on Ladle and emails we send out. You can always change it
                  later.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="you@domain.com"
                  value={currentUser.email}
                  disabled
                />
                <Form.Text className="text-muted">
                  This is the email you logged in with. Looking to log in with a
                  different email? Just{" "}
                  <a href="/#" onClick={() => auth.signOut()}>
                    sign out
                  </a>{" "}
                  and enter your email address.
                </Form.Text>
              </Form.Group>
              <br></br>
              <Button variant="custom" type="submit">
                All done!
              </Button>
            </Form>
          </>
        )}
      </Container>
    </>
  );
}

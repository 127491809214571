import React, { useEffect, useState } from "react";
import { firestore, auth } from "./firebase";
import PropTypes from "prop-types";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [role, setRole] = useState(null);

  // Function for getting custom claim role

  async function getCustomClaimRole() {
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      // If there's no user doc, create one...
      if (user) {
        await firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              firestore.collection("users").doc(user.uid).set({
                name: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                timestamp: new Date(),
              });
            }
          });

        // Add user doc to context
        await firestore
          .collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => {
            const newUser = {
              id: doc.id,
              ...doc.data(),
            };

            setFirestoreUser(newUser);
          });

        // Add claim role to context

        const role = await getCustomClaimRole();
        setRole(role);
      }

      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <div
        style={{
          backgroundColor: "#FFFAF0",
        }}
      ></div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser, // Firebase auth
        firestoreUser, // User document
        role, // Stripe subscription status
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

import React from "react";
import Header from "../components/Header";
import { Form, Col, Row, Container, Alert, Button } from "react-bootstrap";
import { auth, signInWithGoogle } from "../firebase/firebase";
import { Link } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import PropTypes from "prop-types";
import mail from "../assets/mail.gif";

export default function AuthPage({ match }) {
  const { path } = match;
  const [email, setEmail] = React.useState(
    window.localStorage.getItem("emailForSignin") || ""
  );
  const [password, setPassword] = React.useState("");
  const [check, setCheck] = React.useState(false);
  const [manual, setManual] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);

  const signin = path.includes("/signin");
  const signup = path.includes("/signup");
  const forgot = path.includes("/forgot");

  let url = "";

  // Set url depending on environment
  if (process.env.NODE_ENV === "production") {
    url = `https://app.joinladle.com/signin?email=${encodeURIComponent(email)}`;
  }

  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:3000/signin?email=${encodeURIComponent(email)}`;
  }

  // When this component renders
  React.useEffect(() => {
    async function checkMagic() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const url_email = url.searchParams.get("email");
      console.log("Email from URL", url_email);

      if (url_email) {
        // Email in url detected

        setSuccess(
          "🎉 We see you're signing in with a magic link! One moment, while we try to redirect you."
        );

        // Sign the user in

        await auth
          .signInWithEmailLink(url_email, window.location.href)
          .then(() => {
            console.log("Successful sign in with magic link");
          })
          .catch((error) => {
            console.error(error);
            setError(`🐛 ` + error.message);
            setSuccess("");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });

        window.localStorage.clear();
      }
    }

    checkMagic();
  }, []);

  const handleMagic = async (event) => {
    event.preventDefault();

    console.log("Sending magic link");
    setCheck(true);
    auth
      .sendSignInLinkToEmail(email, {
        url: url,
        handleCodeInApp: true,
      })
      .then(() => console.log("Successful sign in with magic link"))
      .catch((error) => {
        setError(`🐛 ` + error.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    setDisabled(true);

    setSuccess("🎉 Your password reset email has been sent. Check your inbox.");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      // After 3 seconds set the alert value to nothing
      setSuccess("");
    }, 5000);

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log("Successful password reset send");
      })
      .catch((error) => {
        console.error(error);
        setError(`🐛 ` + error.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

    setDisabled(false);
  };

  const handleLoginWithPassword = async (event) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log("Successful sign in with email and password");
      })
      .catch((error) => {
        console.error(error);
        setError(`🐛 ` + error.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  const handleSignupWithPassword = async (event) => {
    event.preventDefault();
    setDisabled(true);

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        user.user.sendEmailVerification();
        console.log("Successful sign up with email and password");
      })
      .catch((error) => {
        setError(`🐛 ` + error.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setDisabled(false);
      });
  };

  if (check) {
    return (
      <>
        <Header />
        <br></br>
        <Container fluid className="col-md-6 mb-4">
          <h2 style={{ textAlign: "center" }}>Check your email</h2>
          <br></br>
          <p style={{ textAlign: "center" }}>
            We sent a magic link to your email that will sign you in to your
            dashboard.
          </p>
          <img
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src={mail}
            alt="magic link email"
          />
          <br></br>
        </Container>
      </>
    );
  }

  return (
    <>
      <>
        <Header />
        <br></br>
        <Container fluid className="col-md-5 mb-4">
          <Notifications
            successFunctions={[success, setSuccess]}
            errorFunctions={[error, setError]}
          />
          <br></br>
          <Title signin={signin} signup={signup} forgot={forgot} />
          <br></br>

          {!forgot && (
            <SocialLogins
              errorFunctions={[error, setError]}
              signin={signin}
              signup={signup}
            />
          )}
          <Divider signin={signin} signup={signup} />

          <Row>
            <Col>
              <AuthForms
                signin={signin}
                signup={signup}
                forgot={forgot}
                manual={manual}
                emailFunctions={[email, setEmail]}
                passwordFunctions={[password, setPassword]}
                handleMagic={handleMagic}
                handleLoginWithPassword={handleLoginWithPassword}
                handleSignupWithPassword={handleSignupWithPassword}
                handleResetPassword={handleResetPassword}
                disabled={disabled}
              />
              <AuthLinks
                signin={signin}
                manual={manual}
                signup={signup}
                forgot={forgot}
              />
              <br></br>
              <Alternatives
                signin={signin}
                signup={signup}
                forgot={forgot}
                manualFunctions={[manual, setManual]}
              />
              <Terms />
            </Col>
          </Row>
        </Container>
      </>
    </>
  );
}

const Title = ({ signin, signup, forgot }) => {
  return (
    <>
      {signin && (
        <div>
          <h2 style={{ textAlign: "center" }}>Sign in to Ladle</h2>
          <div className="subtext">
            <Link to="/signup">Don&apos;t have an account? Sign up here.</Link>
          </div>
        </div>
      )}
      {signup && (
        <div>
          <h2 style={{ textAlign: "center" }}>Sign up for Ladle</h2>
          <div className="subtext">
            <Link to="/signin">Already have an account? Sign in here.</Link>
          </div>
        </div>
      )}
      {forgot && <h2 style={{ textAlign: "center" }}>Reset Password</h2>}
    </>
  );
};

const SocialLogins = ({ errorFunctions, signup, signin }) => {
  const [error, setError] = errorFunctions;

  return (
    <>
      {signup && (
        <Row>
          <Col>
            <Button
              onClick={() =>
                signInWithGoogle()
                  .then(() => {
                    console.log("Successful sign up with Google");
                  })
                  .catch((err) => {
                    console.error(err);
                    setError(`🐛 ` + err.message);
                    console.error(error);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  })
              }
              type="submit"
              variant="outline"
              size="lg"
              block
            >
              <FaGoogle className="mr-2" /> Sign up with Google
            </Button>
          </Col>
        </Row>
      )}
      {signin && (
        <Row>
          <Col>
            <Button
              onClick={() =>
                signInWithGoogle()
                  .then(() => {
                    console.log("Successful sign in with Google");
                  })
                  .catch((err) => {
                    console.error(err);
                    setError(`🐛 ` + err.message);
                    console.error(error);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  })
              }
              type="submit"
              variant="outline"
              size="lg"
              block
            >
              <FaGoogle className="mr-2" /> Sign in with Google
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

const Notifications = ({ successFunctions, errorFunctions }) => {
  const [success, setSuccess] = successFunctions;
  const [error, setError] = errorFunctions;

  return (
    <>
      {success && (
        <Alert variant="success" onClose={() => setSuccess("")} dismissible>
          {success}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}
    </>
  );
};

const Terms = () => {
  return (
    <Alert variant="warning">
      🔐 By continuing, you’re agreeing to our{" "}
      <a
        href="https://www.joinladle.com/terms-of-service"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
      ,{" "}
      <a
        href="https://www.joinladle.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      , and{" "}
      <a
        href="https://www.joinladle.com/cookie-policy"
        target="_blank"
        rel="noreferrer"
      >
        Cookie Policy
      </a>
      .
    </Alert>
  );
};

const Email = ({ emailFunctions }) => {
  const [email, setEmail] = emailFunctions;

  const updateEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  return (
    <Form.Group>
      <Form.Label>Email address</Form.Label>
      <Form.Control
        type="email"
        value={email}
        onChange={updateEmail}
        id="email"
        label="Email Address"
        placeholder="you@domain.com"
        name="email"
        size="lg"
        autoFocus
        required
      />
    </Form.Group>
  );
};

const Password = ({ passwordFunctions }) => {
  const [password, setPassword] = passwordFunctions;

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Form.Group>
      <Form.Label>Password</Form.Label>
      <Form.Control
        type="password"
        value={password}
        onChange={updatePassword}
        id="password"
        label="Password"
        placeholder="password"
        name="password"
        size="lg"
        minLength="6"
        required
      />
    </Form.Group>
  );
};

const AuthLinks = ({ signin, manual, signup, forgot }) => {
  return (
    <>
      {signin && (
        <>
          {manual && (
            <div className="subtext">
              <Link to="/signup">Don&apos;t have an account? Sign up.</Link>
            </div>
          )}
          {manual && (
            <div className="subtext">
              <Link to="/forgot">Forgot your password? Reset password.</Link>
            </div>
          )}
        </>
      )}
      {signup && (
        <>
          {manual && (
            <div className="subtext">
              <Link to="/signin">Already have an account? Sign in.</Link>
            </div>
          )}
        </>
      )}
      {forgot && (
        <>
          <div className="subtext">
            <Link to="/signin">Return to sign in page.</Link>
          </div>
        </>
      )}
    </>
  );
};

const AuthForms = ({
  emailFunctions,
  passwordFunctions,
  signup,
  signin,
  forgot,
  manual,
  handleMagic,
  handleLoginWithPassword,
  handleSignupWithPassword,
  handleResetPassword,
  disabled,
}) => {
  const [email, setEmail] = emailFunctions;
  const [password, setPassword] = passwordFunctions;

  return (
    <>
      {signin && (
        <>
          {!manual ? (
            <Form onSubmit={handleMagic}>
              <Email emailFunctions={[email, setEmail]} />
              <Button type="submit" variant="custom" block size="lg">
                Sign in with email
              </Button>
            </Form>
          ) : (
            <Form onSubmit={handleLoginWithPassword}>
              <Email emailFunctions={[email, setEmail]} />
              <Password passwordFunctions={[password, setPassword]} />
              <Button type="submit" variant="custom" block size="lg">
                Sign in with email & password
              </Button>
            </Form>
          )}
        </>
      )}
      {signup && (
        <>
          {!manual ? (
            <Form onSubmit={handleMagic}>
              <Email emailFunctions={[email, setEmail]} />
              <Button type="submit" variant="custom" block size="lg">
                Sign up with email
              </Button>
            </Form>
          ) : (
            <Form onSubmit={handleSignupWithPassword}>
              <Email emailFunctions={[email, setEmail]} />
              <Password passwordFunctions={[password, setPassword]} />
              <Button
                type="submit"
                variant="custom"
                block
                size="lg"
                disabled={disabled}
              >
                Sign up with Email & Password
              </Button>
            </Form>
          )}
        </>
      )}
      {forgot && (
        <>
          <Form onSubmit={handleResetPassword}>
            <Email emailFunctions={[email, setEmail]} />
            <Button
              type="submit"
              variant="custom"
              block
              size="lg"
              disabled={disabled}
            >
              Get a Reset Link
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

const Alternatives = ({ signin, signup, forgot }) => {
  return (
    <>
      {signin && (
        <Alert variant="secondary">
          🪄 We’ll email you a magic link for a passwordless sign in.
        </Alert>
        //   <Alert variant="secondary">
        //     {!manual
        //       ? `🪄 We’ll email you a magic link for a passwordless sign in.
        // You can also`
        //       : `🪄 For an easier flow, you can also`}{" "}
        //     <Link to="/signin" onClick={() => setManual(!manual)}>
        //       {!manual ? `sign in manually` : `sign in with magic link`}
        //     </Link>{" "}
        //     instead.
        //   </Alert>
      )}
      {signup && (
        <Alert variant="secondary">
          🪄 We’ll email you a magic link for a passwordless sign up.
        </Alert>
        //   <Alert variant="secondary">
        //     {!manual
        //       ? `🪄 We’ll email you a magic link for a passwordless sign up.
        // You can also`
        //       : `🪄 For an easier flow, you can also`}{" "}
        //     <Link to="/signup" onClick={() => setManual(!manual)}>
        //       {!manual ? `sign up manually` : `sign up with magic link`}
        //     </Link>{" "}
        //     instead.
        //   </Alert>
      )}
      {forgot && (
        <Alert variant="secondary">
          🪄 We’ll email you a link to reset your password.
        </Alert>
      )}
    </>
  );
};

const Divider = ({ signin, signup }) => {
  return (
    <>
      {(signin || signup) && (
        <div className="divider">
          <br></br>
          <span>or continue with</span>
          <br></br>
        </div>
      )}
    </>
  );
};

AuthPage.propTypes = {
  match: PropTypes.node,
};

Divider.propTypes = {
  signin: PropTypes.node,
  signup: PropTypes.node,
};

Alternatives.propTypes = {
  signin: PropTypes.node,
  manualFunctions: PropTypes.node,
  signup: PropTypes.node,
  forgot: PropTypes.node,
};

AuthLinks.propTypes = {
  signin: PropTypes.node,
  manual: PropTypes.node,
  signup: PropTypes.node,
  forgot: PropTypes.node,
};

AuthForms.propTypes = {
  emailFunctions: PropTypes.node,
  passwordFunctions: PropTypes.node,
  signup: PropTypes.node,
  signin: PropTypes.node,
  forgot: PropTypes.node,
  manual: PropTypes.node,
  handleMagic: PropTypes.node,
  handleLoginWithPassword: PropTypes.node,
  handleSignupWithPassword: PropTypes.node,
  handleResetPassword: PropTypes.node,
  disabled: PropTypes.node,
};

Password.propTypes = {
  passwordFunctions: PropTypes.node,
};

Email.propTypes = {
  emailFunctions: PropTypes.node,
};

Notifications.propTypes = {
  successFunctions: PropTypes.node,
  errorFunctions: PropTypes.node,
};

SocialLogins.propTypes = {
  errorFunctions: PropTypes.node,
  signin: PropTypes.node,
  signup: PropTypes.node,
};

Title.propTypes = {
  signin: PropTypes.node,
  signup: PropTypes.node,
  forgot: PropTypes.node,
};

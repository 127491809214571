import React, { useEffect, useState, useContext } from "react";
import { functions, firestore } from "../firebase/firebase";
import Header from "../components/Header";
import Container from "react-bootstrap/Container";
import Emoji from "../components/Emoji";
import { Alert, Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AuthContext } from "../firebase/authentication";

export default function BillingPage() {
  const { currentUser } = useContext(AuthContext);
  const [products, setProducts] = useState(null);
  const [subs, setSubs] = useState(null);
  const [openingCheckout, setOpeningCheckout] = useState(false);
  const [openingPortal, setOpeningPortal] = useState(false);

  const fetchProducts = () => {
    const unsubscribe = firestore
      .collection("products")
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        const newProducts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(newProducts);
      });

    return () => unsubscribe();
  };

  const fetchSubscriptions = () => {
    const unsubscribe = firestore
      .collection("users")
      .doc(currentUser.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"])
      .onSnapshot(async (snapshot) => {
        // In this implementation we only expect one active or trialing subscription to exist.
        const newSubs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSubs(newSubs);
      });
    return () => unsubscribe();
  };

  useEffect(fetchSubscriptions, [currentUser]);
  useEffect(fetchProducts, []);

  const handleCheckout = async (price) => {
    setOpeningCheckout(true);
    const docRef = await firestore
      .collection("users")
      .doc(currentUser.uid)
      .collection("checkout_sessions")
      .add({
        price: price,
        success_url: window.location.origin + "/billing",
        cancel_url: window.location.origin + "/billing",
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const handlePortal = async () => {
    setOpeningPortal(true);
    const functionRef = functions.httpsCallable(
      "ext-firestore-stripe-subscriptions-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin + "/billing",
    });
    window.location.assign(data.url);
  };

  products && console.log("products", products);
  subs && console.log("subs", subs);

  return (
    products && (
      <>
        <Header />

        <Container fluid className="col-md-6 mb-4">
          <Breadcrumb>
            <LinkContainer to="/">
              <Breadcrumb.Item>
                <Emoji symbol="🏠" /> Home
              </Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to="/account">
              <Breadcrumb.Item>
                <Emoji symbol="🛠️" /> Account
              </Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>
              <Emoji symbol="💳" /> Billing
            </Breadcrumb.Item>
          </Breadcrumb>
          {openingCheckout && (
            <>
              <Alert variant="primary">
                <Alert.Heading>
                  <strong>Loading Checkout... 🏦</strong>
                </Alert.Heading>
                Hold on one moment while we redirect you to your checkout page.
              </Alert>
              <br></br>
            </>
          )}
          {openingPortal && (
            <>
              <Alert variant="primary">
                <Alert.Heading>
                  <strong>Loading... 🏦</strong>
                </Alert.Heading>
                Hold on one moment while we redirect you.
              </Alert>
              <br></br>
            </>
          )}
          <h2>Billing</h2>
          <p>
            You can manage your billing from this page. If you have any
            questions, just <a href="mailto:bkkilaru@gmail.com">email us</a> and
            we&apos;ll get back to you shortly.
          </p>
          <Row>
            <Col>
              {!subs.length && (
                <>
                  <Card
                    onClick={() =>
                      handleCheckout("price_1JCFHUKA4peWROjAI2JlonBN")
                    }
                    border="secondary"
                    style={{ cursor: "pointer" }}
                  >
                    <Card.Header>
                      <Emoji symbol="💳" /> Monthly Subscription
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>Subscribe for $3/mo</Card.Title>
                      <Card.Text>
                        Subscribe now for $3/mo. Click here and we&apos;ll
                        direct you to a billing page to complete your
                        subscription.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <br></br>
                  <Card
                    onClick={() =>
                      handleCheckout("price_1JCFHUKA4peWROjAOS671zIZ")
                    }
                    border="secondary"
                    style={{ cursor: "pointer" }}
                  >
                    <Card.Header>
                      <Emoji symbol="💳" /> Annual Subscription
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>
                        Subscribe for $30/yr (2 months free!)
                      </Card.Title>
                      <Card.Text>
                        Subscribe now for $30/yr. Click here and we&apos;ll
                        direct you to a billing page to complete your
                        subscription.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </>
              )}

              {subs.map((sub, index) => (
                <Card
                  key={index}
                  onClick={handlePortal}
                  border="secondary"
                  style={{ cursor: "pointer" }}
                >
                  <Card.Header>
                    <Emoji symbol="💳" /> Manage Subscription
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>Manage Subscription</Card.Title>
                    <Card.Text>
                      You&apos;re subscribed to Ladle ✅. We&apos;re glad to
                      have you here! Click here to manage your subscription.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>
        </Container>
      </>
    )
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Row, Card, ProgressBar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Confetti from "react-confetti";
import DeliveryTimePicker from "../components/DeliveryTimePicker";
import Emoji from "../components/Emoji";
import Feedback from "../components/Feedback";
import Header from "../components/Header";
import AuthButton from "../components/twitter/AuthButton";
import { AuthContext } from "../firebase/authentication";
import { firestore } from "../firebase/firebase";
import { format } from "date-fns";
import { useHistory } from "react-router";

export default function HomePage() {
  const { currentUser, firestoreUser } = useContext(AuthContext);
  const [detoxes, setDetoxes] = useState(null);
  const [issues, setIssues] = useState(null);
  const [error, setError] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();

  const fetchDetoxes = () => {
    const unsubscribe = firestore
      .collection("detoxes")
      .where("owner", "==", currentUser.uid)
      .where("archived", "==", false)
      .onSnapshot(
        (snapshot) => {
          const newDetoxes = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDetoxes(newDetoxes);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  const fetchIssues = () => {
    const unsubscribe = firestore
      .collection("issues")
      .where("owner", "==", currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const newIssues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setIssues(newIssues);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  useEffect(fetchDetoxes, [currentUser]);

  useEffect(fetchIssues, [currentUser]);

  error && console.log(error);

  if (!detoxes || !issues) {
    return <></>;
  }

  if (!firestoreUser?.twitter?.authorized) {
    return (
      <>
        <Header />
        <Container fluid>
          <br></br>
          <Container fluid className="col-md-6 mb-4">
            <h2>Welcome to Ladle!</h2>
            <br></br>
            <p>
              <Emoji symbol="👋" /> Hey {firestoreUser.name}! Ladle connects to
              your Twitter account to fetch top tweets. Let&apos;s connect to
              yours to send you <strong>Issue No.1!</strong>
            </p>
            <br></br>
            <Row>
              <Col>
                <AuthButton block size="lg">
                  <Emoji symbol="✨" /> {` Connect Your Twitter `}
                  <Emoji symbol="✨" />
                </AuthButton>
              </Col>
            </Row>
            <br></br>
            <Alert variant="warning">
              🔐 We only use your Twitter connection to receive tweets from your
              timeline to compose your daily Ladle email.
            </Alert>
          </Container>
        </Container>
      </>
    );
  }

  const timeZoneOffsetHours = new Date().getTimezoneOffset() / 60; // hours of offset, ex: 8 in pst
  const handleStartLadle = async (e) => {
    e.preventDefault();

    const sendHour = 7; // 7am in your local timezone
    const deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 1);
    deliveryDate.setHours(sendHour);
    deliveryDate.setMinutes(0);
    deliveryDate.setSeconds(0);
    deliveryDate.setMilliseconds(0);

    const newDetox = {
      archived: false,
      createdAt: new Date(),
      title: "Ladle",
      owner: currentUser.uid,
      nextIssueNumber: 1,
      nextIssueDeliveryDate: deliveryDate,
      userId: currentUser.uid,
    };

    // set timezone adjusted delivery hour
    await firestore
      .collection("users")
      .doc(firestoreUser.id)
      .update({
        deliveryHour: sendHour + timeZoneOffsetHours,
      });

    const addNewDetox = await firestore.collection("detoxes").add(newDetox);

    console.log("Add New Detox", addNewDetox);
    setConfetti(true);
  };

  if (!detoxes.length > 0) {
    return (
      <>
        <Header />
        <Container fluid>
          <br></br>
          <Container fluid className="col-md-6 mb-4">
            <h2>📮 Ready, Set, ...</h2>
            <br></br>
            <p>
              <Emoji symbol="👋" /> Hey {firestoreUser.name}! Ladle is connected
              successfully to your Twitter account. Get your first Ladle by
              clicking the button below.
            </p>
            <br></br>
            <Button variant="custom" size="lg" block onClick={handleStartLadle}>
              Get Your First Serving
            </Button>
          </Container>
        </Container>
      </>
    );
  }

  const issueSentAtElement = (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
      <span style={{ marginRight: 8 }}>Your next issue will be sent at</span>
      <DeliveryTimePicker
        startHour={firestoreUser.deliveryHour - timeZoneOffsetHours ?? 7}
      />
    </div>
  );

  return (
    <>
      <Header />
      <Container fluid>
        {issues.length > 0 &&
          issues[issues.length - 1].issueSent &&
          confetti && (
            <Confetti
              recycle={false}
              gravity={0.1}
              numberOfPieces={1000}
              tweenDuration={30000}
              wind={0.01}
            />
          )}
        <br></br>
        <Container fluid className="col-md-6 mb-4">
          {issues.length > 0 && issues[issues.length - 1].issueSent ? (
            <>
              <h2>
                📨 Check Your Inbox for Issue No.
                {issues[issues.length - 1].number}!
              </h2>
              <br></br>
              <div>{issueSentAtElement}</div>
              <p>
                If you have any questions, just send us feedback and we&apos;ll
                get back to you ASAP.
              </p>
              <br></br>
              <Button
                variant="custom"
                size="lg"
                block
                onClick={() => setModalShow(true)}
              >
                <Emoji symbol="✨" /> Send Us Feedback <Emoji symbol="✨" />
              </Button>
            </>
          ) : (
            <>
              <h2>✍️ We&apos;re drafting your next issue...</h2>
              <br></br>
              <p>
                Your next issue will be sent to you in the{" "}
                <strong>next minute or so</strong>.<br></br>
                <br></br>
                <ProgressBar animated now={83} />
              </p>
            </>
          )}

          {issues.filter((x) => x.issueSent && x.emailHTML).length > 0 && (
            <>
              <br></br>
              <br></br>
              <div className="divider">
                <span>Your Issues</span>
              </div>
              <br></br>
              <br></br>
              {issues
                .filter((x) => x.issueSent && x.emailHTML)
                .sort((a, b) => b.number - a.number)
                .map((issue, index) => (
                  <React.Fragment key={index}>
                    <Card
                      onClick={() => history.push(`/issue/${issue.id}`)}
                      border="secondary"
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Header>
                        <Emoji symbol="📨" /> Issue No.{issue.number}
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          {detoxes[detoxes.length - 1].title}: Issue No.
                          {issue.number}
                        </Card.Title>
                        <Card.Text>
                          {format(issue.deliveryDate.toDate(), "iiii, MMMM do")}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <br></br>
                  </React.Fragment>
                ))}
            </>
          )}
        </Container>
        <Feedback
          currentUser={currentUser}
          firestoreUser={firestoreUser}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Container>
    </>
  );
}

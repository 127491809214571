import React, { useEffect } from "react";
import { Row, Container, Spinner } from "react-bootstrap";
import queryString from "query-string";
import { functions } from "../firebase/firebase";
import Header from "../components/Header";
import { navigateTo } from "../common/navigation";

export default function TwitterCallbackPage() {
  useEffect(() => {
    (async () => {
      const { oauth_token, oauth_verifier, denied } = queryString.parse(
        window.location.search
      );

      if (denied) {
        navigateTo("/");
        return;
      }

      if (oauth_token && oauth_verifier) {
        const accessTokenRequest = functions.httpsCallable(
          "TwitterOAuthAccessToken"
        );
        accessTokenRequest({ oauth_token, oauth_verifier }).then(() => {
          navigateTo("/");
        });
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <Container fluid>
        <br></br>
        <Container fluid className="col-md-6 mb-4">
          <h2>🐦 Completing Twitter Authorization...</h2>
          <br></br>
          <p>
            Give us a moment while we complete our connection to your Twitter
            account.
          </p>
          <br></br>
          <Row className="justify-content-md-center">
            <Spinner animation="border" role="status" />
          </Row>
        </Container>
      </Container>
    </>
  );
}

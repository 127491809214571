import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../firebase/authentication";
import Emoji from "../Emoji";
import AuthButton from "./AuthButton";

function Authorize({ cardProps }) {
  const { firestoreUser } = useContext(AuthContext);

  return (
    <Card {...cardProps}>
      <Card.Header>
        <Emoji symbol="🐦" /> Twitter
      </Card.Header>
      <Card.Body>
        <Card.Title>Twitter</Card.Title>
        {firestoreUser?.twitter?.authorized ? (
          <Row>
            <Col>
              <Card.Text>Your Twitter account is connected!</Card.Text>
            </Col>
            <Col xs="4">
              <AuthButton className="float-right">Re-authorize</AuthButton>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Card.Text>Please connect to your Twitter account</Card.Text>
            </Col>
            <Col xs="4">
              <AuthButton className="float-right">Authorize</AuthButton>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

Authorize.propTypes = {
  cardProps: PropTypes.object,
};

export default Authorize;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import firebaseConfig from "./config";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// if (window.location.hostname === "localhost") {
//   console.log(
//     "testing locally -- hitting local functions and firestore emulators"
//   );
//   firebase.functions().useFunctionsEmulator("http://localhost:5001");
//   firebase.firestore().settings({
//     host: "localhost:8080",
//     ssl: false,
//   });
// }

export const functions = firebase.functions();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const createTimestamp = firebase.firestore.FieldValue.serverTimestamp;

const providerGoogle = new firebase.auth.GoogleAuthProvider();
const providerTwitter = new firebase.auth.TwitterAuthProvider();
const providerFacebook = new firebase.auth.FacebookAuthProvider();
providerGoogle.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(providerGoogle);
export const signInWithTwitter = () => auth.signInWithPopup(providerTwitter);
export const signInWithFacebook = () => auth.signInWithPopup(providerFacebook);

export default firebase;

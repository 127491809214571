import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Button, Card, Col, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import Emoji from "../components/Emoji";
import Header from "../components/Header";
import AuthorizeTwitter from "../components/twitter/AuthorizeCard";
import { AuthContext } from "../firebase/authentication";
import { firestore } from "../firebase/firebase";

export default function AccountPage() {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [detoxes, setDetoxes] = useState(null);
  const [error, setError] = useState(false);

  const deleteLadle = async () => {
    const ladleIdToDelete = detoxes[0].id;
    console.log("Deleting Ladle ", ladleIdToDelete);

    const archiveById = firestore
      .collection("detoxes")
      .doc(ladleIdToDelete)
      .update({ archived: true })
      .then(setShowDeleteModal(false))
      .catch((err) => {
        setError(err);
      });

    return () => archiveById();
  };

  const confirmLadleDeletion = () => {
    setShowDeleteModal(true);
    return;
  };

  const fetchDetoxes = () => {
    const unsubscribe = firestore
      .collection("detoxes")
      .where("owner", "==", currentUser.uid)
      .where("archived", "==", false)
      .onSnapshot(
        (snapshot) => {
          const newDetoxes = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDetoxes(newDetoxes);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  useEffect(fetchDetoxes, [currentUser]);

  console.log(error);

  if (!detoxes) {
    return <></>;
  }

  return (
    <>
      <Header />

      <Container fluid className="col-md-6 mb-4">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item>
              <Emoji symbol="🏠" /> Home
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            <Emoji symbol="🛠️" /> Account
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>Account</h2>
        <p>
          You can manage your account from this page. If you have any questions,
          just <a href="mailto:bkkilaru@gmail.com">email us</a> and we&apos;ll
          get back to you shortly.
        </p>
        <Row>
          <Col>
            <AuthorizeTwitter cardProps={{ border: "secondary" }} />
            <br />
            <Card
              onClick={() => history.push(`/profile`)}
              border="secondary"
              style={{ cursor: "pointer" }}
            >
              <Card.Header>
                <Emoji symbol="😏" /> Profile
              </Card.Header>
              <Card.Body>
                <Card.Title>Profile</Card.Title>
                <Card.Text>
                  Manage your profile from here, including editing your name,
                  email, and more.
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
            <Card
              onClick={() => history.push(`/billing`)}
              border="secondary"
              style={{ cursor: "pointer" }}
            >
              <Card.Header>
                <Emoji symbol="💳" /> Billing
              </Card.Header>
              <Card.Body>
                <Card.Title>Billing</Card.Title>
                <Card.Text>
                  Manage your Ladle subscription from here. You can subscribe to
                  monthly or annual plans. You can also manage your current
                  subscription.
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
            {detoxes.length > 0 && (
              <Card
                onClick={() => confirmLadleDeletion()}
                border="secondary"
                style={{ cursor: "pointer" }}
              >
                <Card.Header>
                  <Emoji symbol="🛑" /> Delete
                </Card.Header>
                <Card.Body>
                  <Card.Title>Delete</Card.Title>
                  <Card.Text>
                    Want to stop receiving this Ladle? This will delete your
                    Ladle and can&apos;t be undone without contacting support.
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
        <Modal
          show={showDeleteModal}
          centered
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Ladle deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to stop receiving this Ladle? This can&apos;t
            be undone without contacting support.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="delete" onClick={() => deleteLadle()}>
              Delete Ladle
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

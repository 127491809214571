const firebaseConfig = {
  apiKey: "AIzaSyD4Wfr8vnMWRmP4WRqkrDKbdtOlW6Ziz2o",
  authDomain: "app.joinladle.com",
  projectId: "detox-a9ab8",
  storageBucket: "detox-a9ab8.appspot.com",
  messagingSenderId: "379245422746",
  appId: "1:379245422746:web:fb5ace4cdf857264835723",
};

export default firebaseConfig;

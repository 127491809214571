import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../firebase/authentication";
import OnboardingPage from "../pages/OnboardingPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser, firestoreUser } = useContext(AuthContext);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        currentUser ? (
          firestoreUser &&
          (firestoreUser?.submitted ? (
            <Component {...props} />
          ) : (
            <OnboardingPage />
          ))
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};

export default PrivateRoute;

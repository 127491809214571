/**
 * ex: navigateTo('/settings')
 */
export function navigateTo(path) {
  if (path[0] === "/") {
    window.location.href = window.location.origin + path;
  } else {
    window.location.href = path;
  }
}

import React, { useState, useEffect } from "react";
import { firestore } from "../firebase/firebase";
import Header from "../components/Header";
import Emoji from "../components/Emoji";
import { Breadcrumb, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
// import { AuthContext } from "../firebase/authentication";
import PropTypes from "prop-types";
import ErrorPage from "./ErrorPage";

export default function EmailPage({ match }) {
  //   const { currentUser } = useContext(AuthContext);
  const {
    params: { detoxId },
  } = match;
  const [detox, setDetox] = useState(null);
  const [error, setError] = useState(false);

  const fetchDetox = () => {
    const unsubscribe = firestore
      .collection("detoxes")
      .doc(detoxId)
      .onSnapshot(
        (doc) => {
          const newDetox = {
            id: doc.id,
            ...doc.data(),
          };
          setDetox(newDetox);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  useEffect(fetchDetox, [detoxId]);

  error && console.log(error);

  return (
    detox &&
    (detox.title ? (
      <>
        <Header />
        <Container fluid className="col-md-6 mb-4">
          <Breadcrumb>
            <LinkContainer to="/">
              <Breadcrumb.Item>
                <Emoji symbol="🏠" /> Home
              </Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>
              <Emoji symbol="📧" /> {detox.title}
            </Breadcrumb.Item>
          </Breadcrumb>
          <>
            <h3>{detox.title}</h3>
            <br></br>
            <p>
              Check your inbox! Your first issue was just sent to you and your
              next issue will be sent at 7AM PT!
            </p>
          </>
        </Container>
      </>
    ) : (
      <ErrorPage />
    ))
  );
}

EmailPage.propTypes = {
  match: PropTypes.any,
};

import React, { useState } from "react";
import { Dropdown, Toast } from "react-bootstrap";
import { functions } from "../firebase/firebase";

import "./DeliveryTimePicker.css";

const HOURS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const hourToTimeStr = (hour) => {
  const ampm = hour >= 12 ? "PM" : "AM";
  const hourStr = hour % 12 === 0 ? 12 : hour % 12;

  return `${hourStr} ${ampm}`;
};

export default function DeliveryTimePicker({ startHour }) {
  const [selectedHour, setSelectedHour] = useState(startHour);
  const [showToast, setShowToast] = useState(false);
  // const { firestoreUser } = useContext(AuthContext);

  const handleHourChange = async (newHour) => {
    newHour = parseInt(newHour);
    if (selectedHour === newHour) return;

    setSelectedHour(newHour);

    const timeZoneOffsetHours = new Date().getTimezoneOffset() / 60; // hours of offset, ex: 8 in pst
    await functions.httpsCallable("UpdateUserDeliveryHour")({
      deliveryHour: newHour + timeZoneOffsetHours,
    });

    // TODO update nextIssueDeliveryDate

    // await firestore
    //   .collection("users")
    //   .doc(firestoreUser.id)
    //   .update({
    //     deliveryHour: Math.min(23, Math.max(0, newHour)),
    //   });

    setShowToast(true);
  };

  return (
    <>
      <div className="delivery-time-picker">
        <Dropdown title="time" onSelect={handleHourChange}>
          <Dropdown.Toggle>
            <span>{hourToTimeStr(selectedHour)}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {HOURS.map((hour) => {
              return (
                <Dropdown.Item
                  eventKey={hour}
                  key={hour}
                  active={hour === selectedHour}
                >
                  {hourToTimeStr(hour)}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="delivery-time-picker-toast">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header closeButton={false}>
              <strong className="me-auto">Success!</strong>
            </Toast.Header>
            <Toast.Body>Time set to {hourToTimeStr(selectedHour)}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
}

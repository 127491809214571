import React, { useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { AuthContext } from "../firebase/authentication";
import { auth } from "../firebase/firebase";
import Emoji from "./Emoji";

const Header = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Navbar expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand>Ladle</Navbar.Brand>
      </LinkContainer>

      {currentUser && (
        <>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              border: "none",
            }}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer to="/account">
                <Nav.Link>
                  <Emoji symbol="🛠️" /> Account
                </Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={() => auth.signOut()}>
                <Emoji symbol="👋‍" /> Sign Out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
};

export default Header;

import React from "react";
import { Switch, Route } from "react-router-dom";
import { AuthProvider } from "./firebase/authentication";

// Routing
import PrivateRoute from "./routing/PrivateRoute";
import PublicRoute from "./routing/PublicRoute";

// Main pages
import HomePage from "./pages/HomePage";
import AccountPage from "./pages/AccountPage";
import ProfilePage from "./pages/ProfilePage";
import ErrorPage from "./pages/ErrorPage";
import DetoxPage from "./pages/DetoxPage";
import IssuePage from "./pages/IssuePage";
import BillingPage from "./pages/BillingPage";
import OnboardingPage from "./pages/OnboardingPage";
import AuthPage from "./pages/AuthPage";
import TwitterCallbackPage from "./pages/TwitterCallbackPage";

import "./App.css";

function App() {
  return (
    <AuthProvider>
      <div
        style={{
          backgroundColor: "#FAF7F1",
        }}
      >
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/onboarding" component={OnboardingPage} />
          <PrivateRoute exact path="/detox/:detoxId" component={DetoxPage} />
          <PrivateRoute exact path="/issue/:issueId" component={IssuePage} />
          <PrivateRoute exact path="/account" component={AccountPage} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/billing" component={BillingPage} />
          <PrivateRoute
            exact
            path="/settings/twitter/oauth_callback"
            component={TwitterCallbackPage}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/signin"
            component={AuthPage}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/signup"
            component={AuthPage}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/forgot"
            component={AuthPage}
          />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </AuthProvider>
  );
}

export default App;

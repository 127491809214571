import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { TWITTER_API_URL } from "../../common/constants";
import { navigateTo } from "../../common/navigation";
import { functions } from "../../firebase/firebase";

export default function AuthButton({ children, className, block, size }) {
  const [authLoading, setAuthLoading] = useState(false);

  let url;

  if (process.env.NODE_ENV === "production") {
    url = `https://app.joinladle.com`;
  }

  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:3000`;
  }

  console.log(url);

  const cb = async () => {
    setAuthLoading(true);
    try {
      const {
        data: {
          secrets: { oauth_token },
        },
      } = await functions.httpsCallable("TwitterOAuthRequestToken")(url);
      console.log("RESP!", oauth_token);

      navigateTo(
        `${TWITTER_API_URL}/oauth/authorize?oauth_token=${oauth_token}`
      );
    } catch (error) {
      console.error("Error with oauth/request_token", error);
    }
    setAuthLoading(false);
  };

  return (
    <Button
      size={size}
      variant="custom"
      className={className}
      onClick={cb}
      block={block}
      disabled={authLoading}
    >
      {children}
    </Button>
  );
}
